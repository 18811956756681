/**
 * @module useChatStore
 * @description This module describes all methods to receive and send data from OpenAI Chat
 * @see {@link https://ai-chat.beauty.musqogee.com/docs/#/}
 */

import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { io } from 'socket.io-client'
import { useNotificationsStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profile'
import { getRandomInt } from '@/utils/random.js'
import { useRouter } from 'vue-router'
import i18n from '@/i18n.js'

const { t } = i18n.global
import ApiService from '@/services/api.service'
import JwtService from '@/services/jwt.service'

export const useChatStore = defineStore('chat', () => {
  /**
   * @constant socket [declarable]
   * @type {Object}
   * @description Socket.IO instance
   */
  let socket = null

  /**
   * @constant history [mutable]
   * @type {Array}
   * @default []
   * @description Unsorted array of received by REST API history messages
   */
  const history = ref([])

  /**
   * @constant sortedHistory [computable]
   * @type {Array}
   * @default []
   * @returns Sorted by Date (old => new) array of received by REST API history messages
   */
  const sortedHistory = computed(() => {
    return history.value.sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
  })

  /**
   * @constant historyEmpty [computable]
   * @type {Boolean}
   * @default true
   * @returns true | false based on history array length
   */
  const historyEmpty = computed(() => {
    return history.value.length == 0
  })

  /**
   * @constant historyLength [computable]
   * @type {Number}
   * @default 0
   * @returns Current Pinia history length (do not confuse it with totalMessages)
   */
  const historyLength = computed(() => {
    return history.value.length
  })

  /**
   * @constant totalMessages [mutable]
   * @type {Number}
   * @default 0
   * @description Total length of chat history received by REST API
   */
  const totalMessages = ref(0)

  /**
   * @constant userInputEnter [mutable]
   * @type {Number}
   * @default 0
   * @description
   * Each time user changes chat form height with Enter | Backspace
   * this variable will change with randin integer
   */
  const userInputEnter = ref(0)

  /**
   * @constant realtimeMessage [mutable]
   * @type {String}
   * @default ''
   * @description Variable updates each time socket.on('word') fires
   */
  const realtimeMessage = ref('')

  /**
   * @constant phraseIsBroadcasting [computable]
   * @type {Boolean}
   * @default false
   * @returns
   * Returns true while Socket.IO recives socket.on('word') symbol
   * Returns false when event stoped and phrase returned to default empty String
   */
  const phraseIsBroadcasting = computed(() => {
    return realtimeMessage.value.length > 0
  })

  const newMessageAdded = ref(false)

  /**
   * @constant socketAuthenticated [mutable]
   * @type {Boolean}
   * @description Represents if current socket instance is authenticated or not
   */
  const socketAuthenticated = ref(false)

  /**
   * @constant responses [mutable]
   * @type {Object}
   * @description
   * This object contains current API response state for specific reqests
   * You can use that to show preloaders or placeholders in templates
   * @example
   * <div v-if="store.responses.getChatHistory">
   *   I am placeholder
   * </div>
   * <div v-else>
   *   Loaded content
   * </div>
   */
  const responses = ref({
    getChatHistory: false,
    getRealtimePhrase: false,
    sendVoiceRecognition: false,
  })

  /**
   * @function saveToHistory
   * @description
   * Function saves incoming message in Pinia [history] ref
   *
   * @see {@link https://ai-chat.beauty.musqogee.com/docs/#/Messages/get_api_v1_user_messages}
   *
   * @param {Object} args - arguments of message
   * @default - { id, message, date, sender, receiver, isAudio }
   *
   * @param {String} method - method used to manipulate history[], 'push' | 'unshift'
   * 'push' used more often, while 'unshift' is used only when user requests older messages
   *
   * @todo Segmentize chat messages by day like in Telegram
   */
  function saveToHistory({ ...args }, method) {
    const item = {
      ...args,
      new: true // needs to maintain proper scroll position, temporary param
    }

    history.value[method](item)
    setTimeout(() => {
      history.value.filter(msg => msg.id == item.id)[0].new = false
    }, 100)
  }

  /**
   * @function clearChatHistory
   * @description
   * Function that clears chat history and chat history length
   */
  function clearChatHistory() {
    history.value = []
    totalMessages.value = 0
    realtimeMessage.value = ''
  }

  /**
   * @function savePhrase
   * @description
   * This function fires each time Socket.IO receives symbol from server socket.on('word')
   * It contaminates each symbol in phrase, trimming blank spaces at start
   * When bot stops 'speaking' function pushes message to [history] in Pinia
   * After page reload this message will be in REST request .getChatHistory()
   *
   * @see {@link https://youtrack.musqogee.com/issue/LY-304#focus=Comments-4-4149.0-0}
   * @see {@link https://ai-chat.beauty.musqogee.com/docs/#/Messages/get_api_v1_user_messages}
   */
  function savePhrase(symbol) {
    if (symbol !== '[DONE]') {
      realtimeMessage.value += symbol
      responses.value.getRealtimePhrase = true
      if (phraseIsBroadcasting.value) {
        realtimeMessage.value = realtimeMessage.value.trimStart()
      }
    } else {
      saveToHistory({
        id: getRandomInt(),
        date: Date.now(),
        message: realtimeMessage.value,
        sender: 'bot',
        receiver: 'user',
        isAudio: false,
      }, 'push')
      realtimeMessage.value = ''
      responses.value.getRealtimePhrase = false
    }
  }

  /**
   * @function getChatHistory
   * @description Function to get user chat history and save it to Pinia
   *
   * @see {@link https://ai-chat.beauty.musqogee.com/docs/#/Messages/get_api_v1_user_messages}
   *
   * @param {Number} limit - Maximum number of items @default 50 (max: 100)
   * @param {Number} offset - The number of messages to skip from end
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will throw Promise reject if Axios catches some error in .then()
   * Will fire notification that user must login again and send to login page
   */
  function getChatHistory({ limit = 50, offset = 0 }) {
    const notificationsStore = useNotificationsStore()
    const authStore = useAuthStore()
    return new Promise((resolve, reject) => {
      ApiService.init(import.meta.env.VITE_CHAT_API)
      if (JwtService.getToken()) {
        responses.value.getChatHistory = true
        ApiService.setHeader()
        ApiService.get(import.meta.env.VITE_CHAT_HISTORY, {
          limit: limit,
          offset: offset
        })
          .then(({data}) => {
            if (data.success) {
              totalMessages.value = data.count
              if (data.count > 0) {
                for (let i = 0; i < data.rows.length; i++) {
                  saveToHistory(
                    data.rows[i],
                    offset > 0 ? 'unshift' : 'push'
                  )
                }
              }
            } else {
              notificationsStore.pushNotification({
                message: data.message,
                type: 'error'
              })
            }
            resolve({data})
          })
          .catch(err => {
            notificationsStore.pushNotification({
              message: err.message,
              type: 'error'
            })
            console.error(err)
            reject(err)
          })
          .finally(() => {
            setTimeout(() => { responses.value.getChatHistory = false }, 500)
          })
      } else {
        authStore.logOut()
      }
    })
  }

  /**
   * @function sendVoiceRecognition
   * @description Function to send recorded user voice and get recognized text
   *
   * @param {Object} blob 'audio/wav'
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function sendVoiceRecognition(blob) {
    const notificationsStore = useNotificationsStore()
    const authStore = useAuthStore()
    return new Promise((resolve, reject) => {
      responses.value.sendVoiceRecognition = true

      const formData = new FormData()
      formData.append('media', blob, `recording_${authStore.user.name}_${Date.now()}.wav`)

      ApiService.init(import.meta.env.VITE_CHAT_API)
      ApiService.post(
        import.meta.env.VITE_CHAT_SPEECH,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(({data}) => {
          if (data.success) {
            resolve({data})
            if (data.message.length == 0) {
              notificationsStore.pushNotification({
                message: t('chat.voice.repeat'),
                type: 'info'
              })
            }
          } else {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'error'
            })
            reject({data})
          }
        })
        .catch(err => {
          notificationsStore.pushNotification({
            message: err.message,
            type: 'error'
          })
          console.error(err)
          reject(err)
        })
        .finally(() => {
          setTimeout(() => { responses.value.sendVoiceRecognition = false }, 500)
        })
    })
  }

  /**
   * @function emitMessage
   * @description Function sends message to Socket.IO
   */
  function emitMessage(message) {
    responses.value.getRealtimePhrase = true
    socket.emit('message', message)
  }

  /**
   * @function handleSocketConnect
   * @description
   */
  function handleSocketConnect() {
    socket = new io(import.meta.env.VITE_SOCKET_API)
    socket.connect()
    socket.on('connect', () => {
      handleSocketAuth()
      handleSocketError()
      listenUserMessages()
      listenBotMessages()
      listenBotRecomendation()
    })
  }

  /**
   * @function handleSocketAuth
   * @description Describes app behavior on Socket.IO authorization event
   */
  function handleSocketAuth() {
    const notificationsStore = useNotificationsStore()
    const authStore = useAuthStore()
    const router = useRouter()

    socket.on('authorization', result => {
      if (result == 'authenticated') {
        socketAuthenticated.value = true
      } else if (result == 'unauthorized') {
        handleSocketDisconnect()
        notificationsStore.pushNotification({
          message: t('chat.unauthorized'),
          type: 'error'
        })
        socketAuthenticated.value = false
        router.push({ name: 'SignIn' })
      }
    })

    Promise.all([authStore.verifyAuth()]).then(() => {
      socket.emit('authorization', JwtService.getToken())
    })
  }

  /**
   * @function handleSocketError
   * @description Describes app behavior on Socket.IO error event
   */
  function handleSocketError() {
    const notificationsStore = useNotificationsStore()
    socket.on('error', message => {
      notificationsStore.pushNotification({
        message: message,
        type: 'error'
      })
      responses.value.getRealtimePhrase = false
    })
  }

  /**
   * @function handleSocketDisconnect
   * @description Set of Socket.IO events and listeners to disconnect from server
   */
  function handleSocketDisconnect() {
    socket.disconnect()
    socket.off('connect')
    socket.off('authorization')
    socket.off('word')
    socket.off('message')
    socket.off('error')
  }

  /**
   * @function listenUserMessages
   * @description
   * This function listenes to messages of user in ANOTHER TAB or ANOTHER DEVICES
   * and immediately saves them in current active chat
   *
   * @see {@link https://youtrack.musqogee.com/issue/LY-304#focus=Comments-4-4149.0-0}
   */
  function listenUserMessages() {
    if (socket.onMessage) { return }
    socket.onMessage = true
    socket.on('message', message => {
      saveToHistory({
        id: getRandomInt(),
        date: Date.now(),
        message: message.content,
        isAudio: message.isAudio,
        sender: message.sender || message.sender,
        receiver: message.receiver || message.receiver
      }, 'push')
      newMessageAdded.value = true
      setTimeout(() => { newMessageAdded.value = false }, 100)
    })
  }

  /**
   * @function listenBotMessages
   * @description
   * This function listenes bot realtime broadcast and saves words to a phrase
   *
   * @see {@link https://youtrack.musqogee.com/issue/LY-304#focus=Comments-4-4149.0-0}
   */
  function listenBotMessages() {
    if (socket.onWord) { return }
    socket.onWord = true
    socket.on('word', symbol => {
      savePhrase(symbol)
    })
  }

  /**
   * @function listenBotRecomendation
   * @description
   * This function listenes bot AI skincare recomendation and saves it to profile store
   * This recomendation uses in <dashboard-recomendation /> widget
   */
  function listenBotRecomendation() {
    const profileStore = useProfileStore()
    socket.on('recommendations', recomendation => {
      profileStore.saveRecomendation(recomendation)
    })
  }

  return {
    history,
    sortedHistory,
    historyEmpty,
    historyLength,
    totalMessages,
    userInputEnter,
    realtimeMessage,
    phraseIsBroadcasting,
    newMessageAdded,
    socketAuthenticated,
    responses,

    saveToHistory,
    savePhrase,
    clearChatHistory,
    getChatHistory,
    sendVoiceRecognition,
    emitMessage,
    handleSocketConnect,
    handleSocketAuth,
    handleSocketError,
    handleSocketDisconnect,
    listenUserMessages,
    listenBotMessages,
    listenBotRecomendation,
  }
})
